<template>
  <div class="contain">
    <navbar :title="applyStatus ? '银行授信申请详情': '申请银行授信'"></navbar>
    <div class="sub-title">由中国邮政储蓄银行提供金融服务</div>
    <div class="audit-block bg-blue" v-if="applyStatus == 1">
      <div class="bg-pic">
        <van-icon class="bg-ch" name="underway-o" color="#005E9E" :size="$px2vw(164)" />
      </div>
      <div class="audit-cont">
        <div class="audit-title">
          <van-icon name="underway-o" color="#FBFCE4" :size="$px2vw(40)"/>
          <div class="audit-title-txt">资料审核中</div>
        </div>
        <div>资料提交时间：{{form.created_time}}</div>
      </div>
    </div>
    <div class="audit-block bg-orange"  v-if="applyStatus == 3">
      <div class="bg-pic">
        <van-icon name="close" color="#FF7965" :size="$px2vw(164)" />
      </div>
      <div class="audit-cont audit-cont-ch">
        <div class="audit-title">
          <van-icon name="close" color="#FBFCE4" :size="$px2vw(40)"/>
          <div class="audit-title-txt">资料审核不通过</div>
        </div>
        <div>资料提交时间：{{form.created_time}}</div>
        <div class="audit-mg-block" v-if="form.audit_reject">
          <div>不通过原因：</div>
          <div class="audit-msg">{{form.audit_reject}}</div>
        </div>
      </div>
    </div>
    <van-form ref="form" :show-error-message="false" :show-error="false">
      <div class="form-con">
        <div class="section-title">
          <div class="left-block"></div>
          <div>营业执照及身份信息核对</div>
        </div>
        <div class="field-block">
          <div class="field-label"><span class="red">* </span>请上传公司营业执照</div>
          <div class="upload-cell">
            <div v-if="!form.business_license">注：图片大小不超过6M</div>
            <van-image v-else class="preview" :width="$px2vw(66)" fit="cover" :radius="$px2vw(10)" :height="$px2vw(66)" :src="form.business_license" @click="preview()"/>
            <van-uploader accept=".png, .jpg, .jpeg" 
            :before-read="beforeRead" :after-read="afterRead" v-if="applyStatus != 1">
              <div class="upload-cus">
                <van-icon name="add" color="#1AA3FF" :size="$px2vw(32)"/>
                <div class="upload-label">上传图片</div>
              </div>
            </van-uploader>
          </div>
        </div>
        <div class="field-block">
          <div class="field-label"><span class="red">* </span>统一社会信用代码</div>
          <van-field
            v-model="form.credit_code"
            name="credit_code"
            placeholder="请输入统一社会信用代码"
            :rules="[{ required: true, message: '请填写统一社会信用代码' }]"
          />
        </div>
        <div class="field-block">
          <div class="field-label"><span class="red">* </span>营业执照公司名称</div>
          <van-field
            v-model="form.company_name"
            name="company_name"
            placeholder="请输入营业执照公司名称"
            :rules="[{ required: true, message: '请填写营业执照公司名称' }]"
          />
        </div>
        <div class="field-block">
          <div class="field-label"><span class="red">* </span>营业执照公司地址</div>
          <van-field
            v-model="form.company_address"
            name="company_address"
            placeholder="请输入营业执照公司地址"
            :rules="[{ required: true, message: '请填写营业执照公司地址' }]"
          />
        </div>
        <div class="field-block">
          <div class="field-label"><span class="red">* </span>营业执照法定代表人</div>
          <van-field
            v-model="form.company_contact"
            name="company_contact"
            placeholder="请输入营业执照法定代表人"
            :rules="[{ required: true, message: '请填写营业执照法定代表人' }]"
          />
        </div>
        <div class="field-block">
          <div class="field-label"><span class="red">* </span>法定代表人手机号码</div>
          <van-field
            v-model="form.company_mobilephone"
            name="company_mobilephone"
            placeholder="请输入法定代表人手机号码"
            :rules="[{ required: true, message: '请填写法定代表人手机号码' },{validator: isMobile, 'message': '法定代表人手机号码不正确'}]"
          />
        </div>
        <div class="field-block">
          <div class="field-label"><span class="red">* </span>法定代表人身份证号</div>
          <van-field
            v-model="form.company_contact_id_number"
            name="company_contact_id_number"
            placeholder="请输入法定代表人身份证号"
            :rules="[{ required: true, message: '请填写法定代表人身份证号' },{validator: validateIdCard, 'message': '法定代表人身份证号不正确'}]"
          />
        </div>
        <div class="section-title">
          <div class="left-block"></div>
          <div>申请类型及意向额度</div>
        </div>
        <div class="field-block">
          <div class="field-label"><span class="red">* </span>请上传公司营业执照</div>
          <div class="upload-cell selection">
            <div class="upload-cell-cont" @click="showSelection = true">
              <div style="color:#999999">{{form.apply_type == 1 ? '企业' : '个人'}}</div>
              <van-icon name="arrow" color="#999999" />
            </div>
            <div :class="{'selection-block':true, 'selection-active': showSelection}">
              <div :class="{'selection-item':true, 'selection-item-active': form.apply_type == 1}" @click="setSelection(1)">企业</div>
              <div :class="{'selection-item':true, 'selection-item-active': form.apply_type == 0}" @click="setSelection(0)">个人</div>
            </div>
          </div>
        </div>
        <div class="field-block">
          <div class="field-label"><span class="red">* </span>意向授信额度</div>
          <van-field
            v-model="form.intentional_authorization_limit"
            type="digit"
            name="intentional_authorization_limit"
            maxlength="9"
            placeholder="请输入意向授信额度"
            :rules="[{ required: true, message: '请填写意向授信额度' }]"
          />
        </div>
      </div>
      <div class="btn-group">
        <van-notice-bar v-if="showBar" mode="closeable" background="#FFF4F2" color="#999999">
          请准备好夫妻身份证、户口本、结婚证等有效证书！
          <template #right-icon >
            <van-icon name="clear" color="#FF7B7B" :size="$px2vw(30)" @click="closeBar()"/>
          </template>
        </van-notice-bar>
        <div class="btn">
          <van-button class="ch-btn" round block type="info-cus" :disabled="[1,2].indexOf(applyStatus) > -1" @click="onSubmit"><span class="ch-btn-txt">提交资料</span></van-button>
        </div>
      </div>
    </van-form>
    <van-overlay :show="showOverlay" />
    <confirm v-model="showClearDialog" @confirm="setDistinguish" title="">
      <template>
        <div class="tips">检测到您已填写营业执照相关信息，确认使用执照图片识别的信息进行覆盖？</div>
      </template>
    </confirm>
    <van-overlay :show="showSuccess">
      <div class="wrapper" @click.stop>
        <div class="wrapper-con">
          <img class="invitation-pic" :src="require('@/assets/images/invitation.png')">
          <div class="wrapper-closed" @click="colseSuccess"></div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Navbar from "@/components/navbar.vue";
import { ImagePreview } from 'vant';
import { isMobile,validateIdCard, getRand } from "@/lib/utils.js";
import uploadFile from '@/lib/upload.js'
import {addCredit, creditDetail, distinguishLicense} from "@/api/credit.js"
import md5 from 'js-md5'
import compress from '@/lib/compressed-pic.js'
import Confirm from '@/components/confirm.vue';

export default {
  name: 'creditApply',
  components: {
    Navbar,
    Confirm
  },
  data() {
    return {
      form: {
        apply_type: 1,
        business_license: ''
      },
      showBar: true,
      file: {},
      showSelection: false,
      showSuccess: false,
      applyStatus: 0,
      basePath: 'xiaoniao/jicai/cert/',
      showOverlay: false,
      showClearDialog: false,
      distinguishInfo: {},
    }
  },
  created() {
    this.getDetail()
  },
  methods: {
    isMobile,
    validateIdCard,
    onSubmit() {
      if (!this.form.business_license) {
        return this.$toast('请上传营业执照')
      }
      this.$refs.form.validate().then(async res => {
        var data = {...this.form}
        delete data.applyStatus
        delete data.id
        delete data.audit_reject
        delete data.created_time
        addCredit(data)
          .then(res => {
            if (res.code == 0) {
              this.showSuccess = true
            } else {
              this.$toast(res.msg)
            }
          })
      }).catch(err => {
        if (err[0] != undefined) {
          this.$toast(err[0]['message']);
        }
      })
    },
    closeBar() {
      this.showBar = false
    },
    beforeRead(file) {
      if (['image/jpeg', 'image/jpg', 'image/png'].indexOf(file.type) == -1 ) {
        this.$toast('请上传 jpg,jpeg,png 格式的图片');
        return false;
      }
      return true;
    },
    async afterRead(file) {
      // 大于3M压缩图片
      let uploadFile = file.file
      this.showOverlay = true
      if (file.file.size > 3*1024*1024) {
        this.$toast('图片压缩中...');
        let blob = await compress(uploadFile);
        if (blob == '') {
          this.showOverlay = false
          return this.$toast('图片压缩失败，请重新选择');
        }
        blob.name = uploadFile.name
        uploadFile = blob
      }

      // 上传图片
      let tmpFilePath = await this.upload(uploadFile)
      if(tmpFilePath == '') {
        this.showOverlay = false
        return this.$toast('图片上传失败')
      } else {
        let arr = tmpFilePath.split("|")
        if (arr.length > 0) {
          this.form.business_license = arr[0]
          // 识别营业执照
          await this.distinguish('/' + arr[1])
        } else {
          return this.$toast('图片上传失败')
        }
      }
      this.showOverlay = false
    },
    onOversize(file) {
      this.$toast('文件大小不能超过 6M');
    },
    preview() {
      ImagePreview([this.form.business_license])
    },
    setSelection(type) {
      this.form.apply_type = type
      this.showSelection = false
    },
    async upload(file) {
      var fileName = this.basePath + md5(file.name + new Date().getTime()).substr(0, getRand(20, 14));
      if (file.type == "image/jpg" || file.type == "image/jpeg") {
          fileName += ".jpg";
      } else if (file.type == "image/png") {
          fileName += ".png";
      } else {
          fileName += ".jpg";
      }
      return await uploadFile(file, fileName, true)
    },
    colseSuccess() {
      this.showSuccess = false
      this.$router.push({name: 'index'})
    },
    getDetail() {
      creditDetail()
        .then(res => {
          if (res.code ==0) {
            if (res.data && Object.keys(res.data).length > 0) {
              this.form = res.data
              this.applyStatus = this.form.applyStatus
            }
          }
        })
    },
    distinguish(path) {
      distinguishLicense({url: path})
        .then(res => {
          this.showOverlay = false
          if (res.code == 0) {
            this.distinguishInfo = res.data
            if (this.form.credit_code || this.form.company_name || this.form.company_address || this.form.company_contact ) {
              this.showClearDialog = true
            } else {
              this.setDistinguish()
            }
          }
        }).catch(err => {
          console.log(err)
        })
    },
    setDistinguish() {
      let form = {
        credit_code: this.distinguishInfo.RegisterNumber,
        company_name: this.distinguishInfo.Name,
        company_address: this.distinguishInfo.Address,
        company_contact: this.distinguishInfo.LegalPerson
      }
      this.form = {...this.form, ...form}
    }
  }
}
</script>

<style lang="scss" scoped>
.contain{
  background: #fff;
  .sub-title{
    margin-bottom: 36px;
    font-size: 18px;
    font-weight: 400;
    color: #999999;
    text-align: center;
  }
  .audit-block{
    width: 100%;
    position: relative;
    color: #fff;
    overflow: hidden;

    .bg-pic{
      position: absolute;
      right: -5px;
      top: 12px;

      .bg-ch{
        opacity: .32;
      }
    }
    .audit-cont{
      position: inherit;
      z-index: 1;
      padding: 32px 30px;
      font-size: 24px;
      color: #FFFFFF;

      .audit-title{
        margin-bottom: 18px;
        display: flex;
        align-items: center;

        .audit-title-txt{
          margin-left: 20px;
          font-size: 36px;
          font-weight: bold;
          color: #FBFCE4;
        }
      }
      .audit-mg-block{
        display: flex;
        margin-top: 5px;
        .audit-msg{
          width: 530px;
        }
      }
    }
    .audit-cont-ch{
      padding: 15px 30px;
    }
  }
  .bg-blue{
    background: #1AA3FF;
  }
  .bg-orange{
    background: #FF826E;
  }


  .form-con{
    .section-title{
      margin-top: 33px;
      margin-bottom: 26px;
      display: flex;
      align-items: center;
      font-size: 32px;
      font-weight: bold;
      color: #4F4F4F;

      .left-block{
        margin-right: 22px;
        width: 8px;
        height: 36px;
        background: #1AA3FF;
      }
    }
    .field-block{
      padding: 0 30px;

      .field-label{
        margin: 20px 0 10px;
        font-size: 30px;
        font-weight: 500;
        color: #666666;

        .red{
          color: #F33636;
        }
      }
      .van-cell{
        padding: 24px 20px;
        border-radius: 10px;
        line-height: 40px;
      }
      .upload-cell{
        height: 88px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #F4F9FC;
        border-radius: 10px;
        font-size: 28px;
        color: #D8D8D8;

        .preview{
          border-radius: 10px;
          border: 1px solid #C4C4C4;
        }
        .upload-cus{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 240px;
          height: 58px;
          border: 2px solid #1AA3FF;
          border-radius: 10px;
          box-sizing: border-box;

          .upload-label{
            margin-left: 20px;
            font-size: 26px;
            font-weight: 500;
            color: #666666;
          }
        }
      }
      .selection{
        position: relative;

        .upload-cell-cont{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .selection-block{
          width: 100%;
          padding: 23px 0;
          max-height: 0;
          position: absolute;
          left: 0;
          top: 89px;
          background: #fff;
          border-radius: 10px;
          box-shadow: 0px 6px 16px 1px rgba(0,0,0,0.06);
          z-index: 1;
          box-sizing: border-box;
          opacity: 0;
          visibility: hidden;
          transition: all .5s linear;

          .selection-item{
            height: 47px;
            line-height: 47px;
            text-align: center;
            font-weight: 500;
            color: #666666;
          }
          .selection-item-active{
            color: #1AA3FF;
          }
        }
        .selection-active{
          visibility: visible;
          max-height: 140px;
          opacity: 1;
        }
      }
    }
  }
  .btn-group{
    margin-top: 30px;
    .van-notice-bar{
      height: 68px;
      font-size: 26px;
    }
    .btn{
      width: 100%;
      padding: 31px 29px 0;
      @include safe-area-inset-bottom('30px');
      background: #fff;
      box-sizing: border-box;
    }
  }
  .van-overlay{
    z-index: 4;
  }
}
.tips{
  padding: 15px 30px;
  font-size: 32px;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.wrapper-con{
  width: 575px;
  height: 697px;
  position: relative;
}
.invitation-pic{
  width: 100%;
  height: 100%;
}
.wrapper-closed{
  position: absolute;
  width: 320px;
  height: 80px;
  bottom: 28px;
  left: 50%;
  margin-left: -160px;
}
</style>