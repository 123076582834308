import axios from '@/lib/axios'

// 申请授信
export const addCredit = (data) => {
    return axios.request({
        url: 'api/creditApply',
        data: data,
        method: 'post'
    });
};

// 授信详情
export const creditDetail = (data) => {
    return axios.request({
        url: 'api/creditDetail',
        data: data,
        method: 'post'
    });
};

// 授信使用记录
export const creditRecord = (data) => {
    return axios.request({
        url: 'api/creditChange',
        data: data,
        method: 'post'
    });
};


// 识别营业执照
export const distinguishLicense = (data) => {
    return axios.request({
        url: 'api/recognizeBusinessLicense',
        data: data,
        method: 'post'
    });
};